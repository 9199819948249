import {
    CustomError,
    LockIcon,
    ModalHelper,
    MultiFactorInfo,
    PromptPasswordModal,
    PromptTotpModal,
    RowCards,
    TrashIcon,
    User,
    UserCredential,
    displayMostRecentDate,
    formatAuthError,
    message,
    singletonFirebaseAuthService,
    useAuthContext,
    useLang
} from "@vaultinum/vaultinum-sdk";
import { useRef, useState } from "react";
import { AccountLang } from "../../../lang/AccountLang";

export function TotpCard({ factor, onDelete }: { factor: MultiFactorInfo; onDelete: () => unknown }): JSX.Element {
    const lang = useLang<AccountLang>();
    const { user } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);

    const passwordModalRef = useRef<{ doOpen: <T>(callback: (user: User, password: string) => Promise<T>) => Promise<T> }>(null);
    const totpModalRef = useRef<{ doOpen: (callback: (oneTimePassword: string) => Promise<UserCredential>) => Promise<UserCredential> }>(null);

    function withPassword(callback: (user: User, password: string) => Promise<UserCredential>): Promise<UserCredential> {
        if (!passwordModalRef.current) {
            return Promise.reject(new Error("Password modal not initialized"));
        }
        return passwordModalRef.current.doOpen(callback);
    }

    function withTotp(callback: (oneTimePassword: string) => Promise<UserCredential>): Promise<UserCredential> {
        if (!totpModalRef.current) {
            return Promise.reject(new Error("Totp modal not initialized"));
        }
        return totpModalRef.current.doOpen(callback);
    }

    async function unenrollUser(): Promise<void> {
        if (!user) {
            return;
        }
        try {
            setIsLoading(true);
            await singletonFirebaseAuthService.unenrollTotp(user, factor.uid, error => message.error(formatAuthError(error, lang)), { withPassword, withTotp });
            onDelete();
            void message.success(lang.profileSettings.security.remove2FASuccessMessage);
        } catch (error) {
            if (error !== CustomError.CANCELLED_BY_USER) {
                void message.error(lang.profileSettings.security.remove2FAErrorMessage);
            }
        } finally {
            setIsLoading(false);
        }
    }

    function doDeleteConfirmation(): void {
        ModalHelper.Confirm({
            title: lang.profileSettings.security.remove2FA,
            children: lang.profileSettings.security.remove2FAConfirmation,
            okText: lang.shared.delete,
            onConfirm: () => {
                // Don't wait for the promise to resolve to avoid multiple modals to be superposed in case recent login is requested
                void unenrollUser();
            },
            lang
        });
    }

    return (
        <>
            <RowCards.WithActions
                icon={<LockIcon color="green" size="md" />}
                title={{ text: factor.displayName || undefined }}
                children={displayMostRecentDate({ addedDate: new Date(factor.enrollmentTime) }, lang, true)}
                isDisabled={isLoading}
                actions={[
                    {
                        label: lang.profileSettings.security.remove2FA,
                        icon: TrashIcon,
                        isDisabled: isLoading,
                        onClick: doDeleteConfirmation
                    }
                ]}
            />
            <PromptPasswordModal ref={passwordModalRef} />
            <PromptTotpModal ref={totpModalRef} />
        </>
    );
}
