import { LANG_EN, SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import {
    AdaptiveLink,
    BRAND_NAME,
    Button,
    CodeInput,
    CustomError,
    LoadingIcon,
    LockIcon,
    Modal,
    PromptPasswordModal,
    RowCard,
    User,
    UserCredential,
    copyToClipboard,
    formatAuthError,
    message,
    singletonFirebaseAuthService,
    useAuthContext,
    useLang,
    useModal
} from "@vaultinum/vaultinum-sdk";
import { QRCodeSVG } from "qrcode.react";
import { useRef, useState } from "react";
import { AccountLang } from "../../../lang/AccountLang";

type TotpSetup = {
    secretKey: string;
    qrCodeUrl: string;
    finalizeEnrollment: (verificationCode: string) => Promise<void>;
};

enum Steps {
    SCAN_QR_CODE = "SCAN_QR_CODE",
    ENTER_SETUP_KEY = "ENTER_SETUP_KEY",
    INPUT_CODE = "INPUT_CODE"
}

function AndroidLink({ locale }: { locale: SupportedLanguageCode }): JSX.Element {
    const link = `https://play.google.com/store/search?q=Authenticator%20App&c=apps&hl=${locale}`;
    const imgUrl = `img/GetItOnGooglePlay_Badge_Web_color_${locale}.png`;
    return (
        <a title="Google Play" href={link} target="_blank" rel="noreferrer">
            <img alt="Google Play" src={imgUrl} width={150} />
        </a>
    );
}

function IOSLink({ locale }: { locale: SupportedLanguageCode }): JSX.Element {
    const linkLocale = locale === LANG_EN ? "" : locale;
    const link = `https://www.apple.com/${linkLocale}/search/Authentication-app?src=serp`;
    const imgUrl = `img/Download_on_the_App_Store_Badge_${locale.toLocaleUpperCase()}.svg`;
    return (
        <a title="Apple Store" href={link} target="_blank" rel="noreferrer">
            <img alt="Apple Store" src={imgUrl} width={150} />
        </a>
    );
}

function ScanQrCodeStep({
    qrCodeUrl,
    doClose,
    setStep
}: {
    qrCodeUrl: TotpSetup["qrCodeUrl"];
    doClose: () => void;
    setStep: (step: Steps) => void;
}): JSX.Element {
    const lang = useLang<AccountLang>();
    return (
        <>
            <div className="flex flex-col gap-2">
                <div>{lang.profileSettings.security.scanQrCodeInstructions1}</div>
                <div className="flex gap-2 items-center justify-center h-12">
                    <AndroidLink locale={lang.code} />
                    <IOSLink locale={lang.code} />
                </div>
                <div>{lang.profileSettings.security.scanQrCodeInstructions2}</div>
            </div>
            <div className="flex flex-col items-center gap-2">
                <QRCodeSVG value={qrCodeUrl} size={180} />
                <AdaptiveLink onClick={() => setStep(Steps.ENTER_SETUP_KEY)} children={lang.profileSettings.security.cantScanIt} />
            </div>
            <div className="flex gap-2 justify-between w-full">
                <Button children={lang.shared.cancel} isLoading={false} type="default" onClick={doClose} />
                <Button children={lang.shared.next} isLoading={false} onClick={() => setStep(Steps.INPUT_CODE)} />
            </div>
        </>
    );
}

function EnterSetupKeyStep({
    secretKey,
    doClose,
    setStep
}: {
    secretKey: TotpSetup["secretKey"];
    doClose: () => void;
    setStep: (step: Steps) => void;
}): JSX.Element {
    const lang = useLang<AccountLang>();
    return (
        <>
            <div>{lang.profileSettings.security.enterSetupKeyInstructions}</div>
            <div
                className="bg-grey-extra-light border-2 border-grey-light cursor-pointer hover:bg-grey-light px-3 py-2 rounded-full text-lg transition-colors"
                onClick={() => copyToClipboard(secretKey)}
            >
                {secretKey}
            </div>
            <div className="flex justify-between gap-2 w-full">
                <Button children={lang.shared.cancel} isLoading={false} type="default" onClick={doClose} />
                <div className="flex gap-2">
                    <Button children={lang.shared.back} isLoading={false} type="default" onClick={() => setStep(Steps.SCAN_QR_CODE)} />
                    <Button children={lang.shared.next} isLoading={false} onClick={() => setStep(Steps.INPUT_CODE)} />
                </div>
            </div>
        </>
    );
}

function InputCodeStep({
    finalizeEnrollment,
    onSuccess,
    doClose,
    setStep
}: {
    finalizeEnrollment: TotpSetup["finalizeEnrollment"];
    onSuccess: () => unknown;
    doClose: () => unknown;
    setStep: (step: Steps) => void;
}): JSX.Element {
    const lang = useLang<AccountLang>();

    async function doEnrollment(code: string): Promise<void> {
        try {
            await finalizeEnrollment(code);
            void message.success(lang.profileSettings.security.configureSuccessMessage);
            onSuccess();
            doClose();
        } catch (err) {
            void message.error(lang.profileSettings.security.configureErrorMessage);
            // Throw error so CodeInput component can display error message
            throw err;
        }
    }

    return (
        <div className="flex flex-col h-full justify-between w-full">
            <div>{lang.profileSettings.security.inputCodeInstructions}</div>
            <CodeInput codeLength={6} validateCode={doEnrollment} />
            <div className="flex justify-between gap-2 w-full">
                <Button children={lang.shared.cancel} isLoading={false} type="default" onClick={doClose} />
                <div className="flex gap-2">
                    <Button children={lang.shared.back} isLoading={false} type="default" onClick={() => setStep(Steps.SCAN_QR_CODE)} />
                    {/* Disabled button just to give context to user. CodeInput component triggers validation automatically */}
                    <Button children={lang.shared.verify} isLoading={false} isDisabled />
                </div>
            </div>
        </div>
    );
}

export function TotpEnrollmentCard({ onSuccess }: { onSuccess: () => void }): JSX.Element {
    const lang = useLang<AccountLang>();
    const [isLoading, setIsLoading] = useState(false);
    const [totpSetup, setTotpSetup] = useState<{
        secretKey: string;
        qrCodeUrl: string;
        finalizeEnrollment: (verificationCode: string) => Promise<void>;
    }>();
    const { user } = useAuthContext();
    const [step, setStep] = useState<Steps>(Steps.SCAN_QR_CODE);
    const { isOpen, doOpen, doClose } = useModal(() => setStep(Steps.SCAN_QR_CODE));

    const passwordModalRef = useRef<{ doOpen: <T>(callback: (user: User, password: string) => Promise<T>) => Promise<T> }>(null);

    function withPassword(callback: (user: User, password: string) => Promise<UserCredential>): Promise<UserCredential> {
        if (!passwordModalRef.current) {
            return Promise.reject(new Error("Password modal not initialized"));
        }
        return passwordModalRef.current?.doOpen(callback);
    }

    async function withTotpSetup() {
        if (!user) {
            return;
        }
        try {
            setIsLoading(true);
            setTotpSetup(
                await singletonFirebaseAuthService.setupTotp(
                    user,
                    BRAND_NAME,
                    lang.shared.authenticatorApp,
                    error => message.error(formatAuthError(error, lang)),
                    {
                        withPassword
                    }
                )
            );
            doOpen();
        } catch (error) {
            if (error !== CustomError.CANCELLED_BY_USER) {
                void message.error(formatAuthError(error, lang));
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <RowCard
                isDisabled={isLoading}
                onClick={withTotpSetup}
                icon={<LockIcon color="grey" size="md" />}
                title={{ text: lang.profileSettings.security.configure2FA }}
                children={lang.profileSettings.security.configure2FADescription}
                rightChildren={isLoading ? <LoadingIcon /> : null}
                borderOnHover
            />
            <PromptPasswordModal ref={passwordModalRef} />
            <Modal isOpen={isOpen} size="md" hideFooter lang={lang}>
                {totpSetup ? (
                    <div className="flex flex-col items-center gap-4 text-center justify-between h-96">
                        {step === Steps.SCAN_QR_CODE && <ScanQrCodeStep qrCodeUrl={totpSetup.qrCodeUrl} doClose={doClose} setStep={setStep} />}
                        {step === Steps.ENTER_SETUP_KEY && <EnterSetupKeyStep secretKey={totpSetup.secretKey} doClose={doClose} setStep={setStep} />}
                        {step === Steps.INPUT_CODE && (
                            <InputCodeStep finalizeEnrollment={totpSetup.finalizeEnrollment} onSuccess={onSuccess} doClose={doClose} setStep={setStep} />
                        )}
                    </div>
                ) : null}
            </Modal>
        </>
    );
}
