import { AuthError, UserCredential } from "firebase/auth";
import { BaseTrackingEvents, TrackingService } from "../../analytics/trackingService";
import { doPost } from "../apiService";
import { AuthService } from "./auth.service";
import { singletonFirebaseAuthService } from "./firebase.auth.service";

class AuthController {
    private basePath = "auth";

    constructor(
        private authService: AuthService,
        private trackingService: TrackingService
    ) {}

    register(email: string, password: string, domainId?: string): Promise<string> {
        return doPost<string>(`${this.basePath}/register`, { email, password, ...(domainId ? { domainId } : {}) });
    }

    async login(
        email: string,
        password: string,
        onErrorCode: (error: AuthError) => void,
        prompters?: { withTotp?: (callback: (oneTimePassword: string) => Promise<UserCredential>) => Promise<UserCredential> },
        rememberMe = false
    ): Promise<UserCredential> {
        await this.authService.setPersistenceStrategy(rememberMe);
        const userCredential = await this.authService.login(email, password, onErrorCode, prompters);
        this.trackingService.startTrackingUser(userCredential.user?.uid);
        this.trackingService.sendEvent(BaseTrackingEvents.UserLogin, { "remember me": rememberMe });
        return userCredential;
    }

    async logout(): Promise<void> {
        await this.authService.logout();
        localStorage.clear();
        this.trackingService.stopTrackingUser();
    }

    forgotPassword(email: string, domainId?: string): Promise<void> {
        return doPost<void>(`${this.basePath}/forgot-password`, { email, ...(domainId ? { domainId } : {}) });
    }

    resetPassword(email: string, newPassword: string, oobCode: string): Promise<void> {
        return doPost<void>(`${this.basePath}/reset-password`, {
            email,
            newPassword,
            oobCode
        });
    }

    verifyEmail(email: string, domainId?: string): Promise<void> {
        return doPost<void>(`${this.basePath}/verify-email`, { email, ...(domainId ? { domainId } : {}) });
    }
}

export const authController = new AuthController(singletonFirebaseAuthService, new TrackingService());
