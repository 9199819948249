import axios, { AxiosRequestConfig } from "axios";
import { authController } from "./auth/auth.controller";
import { getAuth } from "./firebaseService";

enum HttpMethod {
    GET = "get",
    POST = "post",
    PUT = "put"
}

export const getTokens = async (): Promise<{ bearerToken: string; refreshToken: string }> => {
    const currentUser = getAuth().currentUser;
    const token = await currentUser?.getIdToken();
    const refreshToken = currentUser?.refreshToken;
    if (!token || !refreshToken) {
        await authController.logout();
    }
    return { bearerToken: `Bearer ${token}`, refreshToken: refreshToken || "" };
};

async function getAxiosConfig(config?: AxiosRequestConfig) {
    const { bearerToken, refreshToken } = await getTokens();
    const axiosConfig: AxiosRequestConfig = {
        ...config,
        headers: {
            ...config?.headers,
            Authorization: bearerToken,
            refreshToken
        }
    };
    return axiosConfig;
}

async function doAxiosRequest<T>(method: HttpMethod, url: string, config?: AxiosRequestConfig, data?: unknown): Promise<T> {
    const axiosConfig = await getAxiosConfig(config);
    const result = method === HttpMethod.GET ? await axios.get(`/trust/${url}`, axiosConfig) : await axios[method](`/trust/${url}`, data, axiosConfig);
    if (config?.responseType === "blob") {
        return result.data;
    }
    return result.data.response;
}

export async function doAxiosGet<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return doAxiosRequest<T>(HttpMethod.GET, url, config);
}

export async function doAxiosPost<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> {
    return doAxiosRequest<T>(HttpMethod.POST, url, config, { params: data });
}

export async function doAxiosPut<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> {
    return doAxiosRequest<T>(HttpMethod.PUT, url, config, { params: data });
}
