import {
    APPS_INFOS,
    AppCode,
    AuthenticationLogo,
    BRAND_NAME,
    BaseLang,
    CardWithFeedback,
    LoginForm,
    getUserProfileFromUID,
    useLang,
    useUrlSearch
} from "@vaultinum/vaultinum-sdk";
import { capitalize } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { getPrioritaryUrl } from "../../helpers";
import { AccountLang } from "../../lang/AccountLang";
import { ALLOWED_DOMAIN, ALLOWED_SUBDOMAIN_SUFFIX, APP_PATH, URL as RoutingUrl, isCleanUrlNeeded } from "../../services/routingService";

function isSafeUrl(redirectTo: string | undefined): redirectTo is string {
    if (!redirectTo) {
        return false;
    }
    try {
        const parsedUrl = new URL(redirectTo);
        return parsedUrl.hostname === ALLOWED_DOMAIN || parsedUrl.hostname.endsWith(ALLOWED_SUBDOMAIN_SUFFIX);
    } catch {
        return redirectTo.startsWith(APP_PATH);
    }
}

function isSafeAppCode(appCode: string | undefined): appCode is AppCode {
    return !!appCode && appCode in APPS_INFOS;
}

export default function LoginPage({ logoSubtitle }: { logoSubtitle?: ReactNode }): JSX.Element {
    const lang = useLang<AccountLang & BaseLang>();
    const { redirectTo } = useUrlSearch() as { redirectTo?: string };
    const [isTotpDisplayed, setIsTotpDisplayed] = useState(false);

    useEffect(() => {
        // clear url needed only for apps that are not the account app
        if (redirectTo && isCleanUrlNeeded(redirectTo)) {
            window.history.replaceState({}, "", window.location.pathname);
        }
    }, [redirectTo]);

    return (
        <CardWithFeedback
            extra={<AuthenticationLogo logoSubtitle={logoSubtitle} />}
            title={isTotpDisplayed ? lang.shared.secondFactorAuthentication : lang.shared.signIn}
            subtitle={isTotpDisplayed ? lang.shared.secondFactorAuthenticationInstructions : lang.authentication.loginInstructions(capitalize(BRAND_NAME))}
        >
            <LoginForm
                ref={instance => setIsTotpDisplayed(instance?.isTotpDisplayed ?? false)}
                onSuccess={userCredential => {
                    getUserProfileFromUID(userCredential.user.uid, userProfile => {
                        if (!getPrioritaryUrl(userCredential.user, userProfile)) {
                            const selectedAppCode: string | undefined = userProfile?.selectedAppCode;
                            if (isSafeUrl(redirectTo)) {
                                window.location.assign(redirectTo);
                            } else if (isSafeAppCode(selectedAppCode)) {
                                window.location.assign(APPS_INFOS[selectedAppCode].baseUrl);
                            }
                        }
                    });
                }}
                onError={error => {
                    const url = error.response?.data?.redirectTo;
                    if (isSafeUrl(url)) {
                        window.location.assign(url);
                    }
                }}
                forgotPasswordUrl={RoutingUrl.forgotPassword}
                registerUrl={RoutingUrl.register}
            />
        </CardWithFeedback>
    );
}
