import classNames from "classnames";
import { ReactNode, useState } from "react";
import { Color } from "../../referentials";

export type BaseCardContainerInterface = {
    children: ReactNode;
    leftChildren?: ReactNode;
    rightChildren?: ReactNode;
    header?: ReactNode;
    "data-id"?: string;
    onClick?: () => void;
    border?: {
        color: Color;
        position: "left" | "top";
    };
    isSelected?: boolean;
    isDisabled?: boolean;
    footer?: ReactNode;
    borderOnHover?: boolean;
    growOnHover?: boolean;
};

export default function BaseCardContainer({
    children,
    leftChildren,
    rightChildren,
    header,
    "data-id": dataId,
    onClick,
    border,
    isDisabled,
    isSelected,
    footer,
    borderOnHover = false,
    growOnHover = false
}: BaseCardContainerInterface): JSX.Element {
    const [hover, setHover] = useState(false);
    return (
        <div
            data-id={dataId}
            className={classNames("flex-1 overflow-hidden rounded-lg transition-all ease-in-out border-2 text-slate-primary bg-white", {
                "border-primary": isSelected,
                "border-slate-extra-light": hover && borderOnHover,
                "scale-105": hover && growOnHover
            })}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
        >
            <div
                children={
                    <div
                        className={classNames("flex w-full flex-col", {
                            "h-full": footer,
                            "cursor-pointer": onClick,
                            "pointer-events-none": isDisabled
                        })}
                        {...(!isDisabled && { onClick })}
                    >
                        {header && (
                            <div className="flex w-full">
                                <header className="w-full">{header}</header>
                            </div>
                        )}
                        <div
                            className={classNames("flex w-full", {
                                "flex-1 flex-col": footer
                            })}
                        >
                            {leftChildren}
                            <div className="flex w-full flex-col md:flex-row">
                                {children}
                                {rightChildren && <div className="flex min-w-max items-center gap-5 p-5">{rightChildren}</div>}
                            </div>
                            {footer && <footer className="flex flex-1 items-end p-5">{footer}</footer>}
                        </div>
                    </div>
                }
                className={classNames("h-full transition-opacity duration-500 text-dark", {
                    [`border-${border?.color}-primary`]: border?.color,
                    "border-l-8": border?.color && border?.position === "left",
                    "border-t-8": border?.color && border?.position === "top",
                    "opacity-50": isDisabled,
                    flex: !!leftChildren || !!rightChildren
                })}
            />
        </div>
    );
}
