import {
    ACCOUNT_COLLECTION,
    ACCOUNT_INVITATION_COLLECTION,
    Account,
    AccountInvitation,
    AccountRights,
    AccountUser,
    InvitationStatus
} from "@vaultinum/vaultinum-api";
import {
    DocumentData,
    DocumentSnapshot,
    collection,
    collectionGroup,
    doGet,
    doPost,
    doc,
    getFirestore,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where
} from "@vaultinum/vaultinum-sdk";

export type InvitationsFields = {
    selectedInvitations: AccountInvitation[];
};

const convertDocToAccountInvitation = (invitationDoc: DocumentSnapshot<DocumentData>): AccountInvitation =>
    ({
        ...invitationDoc.data(),
        id: invitationDoc.id,
        sentDate: invitationDoc.data()?.sentDate.toDate()
    }) as AccountInvitation;

export const isAccountInvitation = (item: AccountUser | AccountInvitation): item is AccountInvitation => !!(item as AccountInvitation)?.code;

export function sendAccountInvitation(account: Account, email: string, rights: AccountRights[]): Promise<void> {
    return doPost("account/invite", { email: email.trim(), accountId: account.id, rights });
}

export function getInvitationsByAccountIdAndDomainId(
    accountId: string,
    whiteLabelDomainId: string | undefined,
    onUpdate: (invitations: AccountInvitation[]) => void
): () => void {
    return onSnapshot(
        query(
            collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_INVITATION_COLLECTION),
            where("whiteLabelDomainId", "==", whiteLabelDomainId ?? null)
        ),
        querySnapshot => onUpdate(querySnapshot.docs.map(convertDocToAccountInvitation))
    );
}

export function cancelAccountInvitation(accountInvitation: AccountInvitation): Promise<void> {
    return updateDoc(doc(collection(getFirestore(), ACCOUNT_COLLECTION, accountInvitation.accountId, ACCOUNT_INVITATION_COLLECTION), accountInvitation.id), {
        status: InvitationStatus.CANCELED
    });
}

export function updateAccountInvitationRights(accountInvitation: AccountInvitation, rights: AccountRights[]): Promise<void> {
    return updateDoc(doc(collection(getFirestore(), ACCOUNT_COLLECTION, accountInvitation.accountId, ACCOUNT_INVITATION_COLLECTION), accountInvitation.id), {
        rights
    });
}

export function getPendingInvitationsByEmailAndDomainId(
    email: string,
    whiteLabelDomainId: string | undefined,
    onUpdate: (invitations: AccountInvitation[]) => void
): () => void {
    return onSnapshot(
        query(
            collectionGroup(getFirestore(), ACCOUNT_INVITATION_COLLECTION),
            where("whiteLabelDomainId", "==", whiteLabelDomainId ?? null),
            where("email", "==", email),
            where("status", "==", InvitationStatus.PENDING),
            orderBy("sentDate", "desc")
        ),
        querySnapshot => {
            onUpdate(querySnapshot.docs.map(convertDocToAccountInvitation));
        }
    );
}

export function acceptInvitation(accountInvitation: AccountInvitation): Promise<void> {
    return doGet("account/invite/accept", {
        code: accountInvitation.code,
        email: accountInvitation.email
    });
}

export function rejectInvitation(accountInvitation: AccountInvitation): Promise<void> {
    return updateDoc(doc(collection(getFirestore(), ACCOUNT_COLLECTION, accountInvitation.accountId, ACCOUNT_INVITATION_COLLECTION), accountInvitation.id), {
        status: InvitationStatus.REJECTED
    });
}
