import { EMAIL_REGEX } from "@vaultinum/vaultinum-api";
import { Ref, forwardRef, useImperativeHandle, useState } from "react";
import { Link } from "react-router-dom";
import {
    Button,
    CodeInput,
    Controller,
    CustomError,
    Form,
    Input,
    SUPPORT_EMAIL,
    Separator,
    UserCredential,
    formatAuthError,
    formatEmailHref,
    message,
    useComponentPromise,
    useForm,
    useLang,
    useRequiredString,
    useUrlSearch,
    yup
} from "../../../../common";
import { authController } from "../../services/auth/auth.controller";

type LoginFormInputs = {
    email: string;
    password: string;
    "remember-me": boolean;
};

function LoginForm(
    {
        onSuccess,
        onError,
        forgotPasswordUrl,
        registerUrl
    }: {
        onSuccess?: (userCredential: UserCredential) => unknown;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError?: (error: any) => unknown;
        forgotPasswordUrl?: string;
        registerUrl?: string;
    },
    ref: Ref<{ isTotpDisplayed: boolean }>
): JSX.Element {
    const lang = useLang();
    const [working, setWorking] = useState(false);

    // Form
    const schema = yup.object().shape({
        email: useRequiredString().matches(EMAIL_REGEX, lang.shared.invalidEmail),
        password: useRequiredString()
    });
    const {
        handleSubmit,
        reset,
        control,
        formState: { errors }
    } = useForm<LoginFormInputs>({
        schema,
        delayError: 500,
        mode: "onChange"
    });

    // TOTP
    const [displayTotpInput, setDisplayTotpInput] = useState(false);
    const { trigger, doSubmit, doCancel } = useComponentPromise<UserCredential>({
        show: () => setDisplayTotpInput(true),
        hidden: () => setDisplayTotpInput(false),
        onCancel: reset,
        onError: error => {
            // Throw error so CodeInput component can display error message
            throw error;
        }
    });

    // Expose isTotpDisplayed to parent component
    useImperativeHandle(ref, () => ({ isTotpDisplayed: displayTotpInput }), [displayTotpInput]);

    const { email: initialEmailValue } = useUrlSearch() as { email?: string };

    async function doLogin({ email, password, "remember-me": rememberMe }: LoginFormInputs) {
        try {
            setWorking(true);
            const userCredential = await authController.login(
                email,
                password,
                error => message.error(formatAuthError(error, lang)),
                { withTotp: trigger },
                rememberMe
            );
            onSuccess?.(userCredential);
        } catch (error) {
            if (error === CustomError.CANCELLED_BY_USER) {
                return;
            }
            onError?.(error);
            void message.error(formatAuthError(error, lang));
        } finally {
            setWorking(false);
        }
    }

    if (displayTotpInput) {
        return (
            <div className="flex flex-col gap-4 h-full items-center">
                <div className="flex flex-1 items-center">
                    <CodeInput codeLength={6} validateCode={doSubmit} />
                </div>
                <div className="flex flex-wrap justify-center">
                    {lang.shared.cantUseYourPhone}&nbsp;
                    <a
                        href={formatEmailHref(SUPPORT_EMAIL, lang.email.subject.loginSupportRequest)}
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        children={lang.shared.contactUs}
                    />
                </div>
                <Button children={lang.shared.back} isLoading={false} type="default" onClick={doCancel} />
            </div>
        );
    }
    return (
        <Form onSubmit={handleSubmit(doLogin)}>
            <Controller
                name="email"
                control={control}
                defaultValue={initialEmailValue}
                render={({ field }) => (
                    <Input.Email
                        {...field}
                        data-id="email-input"
                        label={lang.shared.email}
                        autoFocus={!initialEmailValue}
                        disabled={working}
                        errorMessage={errors.email?.message}
                    />
                )}
            />
            <Controller
                name="password"
                data-id="password-control"
                control={control}
                render={({ field }) => (
                    <Input.Password
                        {...field}
                        data-id="password-input"
                        label={lang.shared.password}
                        autoFocus={!!initialEmailValue}
                        disabled={working}
                        errorMessage={errors.password?.message}
                    />
                )}
            />
            <div className="flex items-center justify-between gap-2">
                <Controller
                    control={control}
                    name="remember-me"
                    data-id="remember-control"
                    render={({ field: { onChange } }) => (
                        <Input.Checkbox id="remember-me" data-id="remember-input" label={lang.shared.rememberMe} onChange={onChange} />
                    )}
                />
                {forgotPasswordUrl && (
                    <Link to={forgotPasswordUrl} className="link float-right">
                        {lang.shared.forgotPassword}
                    </Link>
                )}
            </div>
            <Button htmlType="submit" data-id="login-button" isLoading={working} children={lang.shared.signIn} />
            {registerUrl && (
                <>
                    <Separator />
                    <div className="flex flex-wrap justify-center">
                        {lang.shared.notRegistered}&nbsp;
                        <Link to={registerUrl} className="link">
                            {lang.register.register}
                        </Link>
                    </div>
                </>
            )}
        </Form>
    );
}

export default forwardRef(LoginForm);
