import { List, MultiFactorInfo, SectionTitle, singletonFirebaseAuthService, useAuthContext, useLang } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { ViewWrapper } from "../../../components";
import { AccountLang } from "../../../lang/AccountLang";
import { TotpCard } from "./TotpCard";
import { TotpEnrollmentCard } from "./TotpEnrollmentCard";

export default function SecurityView(): JSX.Element {
    const lang = useLang<AccountLang>();
    const { user } = useAuthContext();
    const [factors, setFactors] = useState<MultiFactorInfo[]>(user ? singletonFirebaseAuthService.listEnrolledFactors(user) : []);

    function refreshFactors(): void {
        setFactors(user ? singletonFirebaseAuthService.listEnrolledFactors(user) : []);
    }

    return (
        <ViewWrapper title={lang.profileSettings.menu.security}>
            <div className="flex flex-col gap-4">
                <SectionTitle title={lang.profileSettings.security.multiFactorAuthentication} />
                {factors.length ? (
                    <List list={factors} render={factor => <TotpCard key={factor.uid} factor={factor} onDelete={refreshFactors} />} />
                ) : (
                    <TotpEnrollmentCard onSuccess={refreshFactors} />
                )}
            </div>
        </ViewWrapper>
    );
}
