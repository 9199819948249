import {
    Account,
    ACCOUNT_COLLECTION,
    ACCOUNT_USER_COLLECTION,
    AccountRights,
    AccountRole,
    AccountUser,
    isDefined,
    mapDeepObject
} from "@vaultinum/vaultinum-api";
import { collection, doc, doDelete, doPost, getFirestore, updateDoc } from "@vaultinum/vaultinum-sdk";
import { isString, pickBy } from "lodash";

export enum CreateAs {
    ORGANISATION_MEMBER = "ORGANISATION_MEMBER",
    REPRESENTATIVE = "REPRESENTATIVE"
}

export function deleteAccountUser(account: Account, uid: string): Promise<void> {
    return doDelete(`account/${account.id}/user/${uid}`);
}

export function deleteAccount(account: Account): Promise<void> {
    return doDelete(`account/${account.id}`);
}

export function updateAccountUserRights(accountUser: AccountUser, rights: AccountRights[]): Promise<void> {
    return updateDoc(doc(collection(getFirestore(), ACCOUNT_COLLECTION, accountUser.accountId, ACCOUNT_USER_COLLECTION), accountUser.id), { rights });
}

export function getAccountUserRole(rights: AccountRights[] | undefined): AccountRole | undefined {
    if (!rights) {
        return undefined;
    }
    if (rights.includes(AccountRights.ADMIN)) {
        return AccountRole.ADMIN;
    }
    if (rights.includes(AccountRights.INVITE)) {
        return AccountRole.REPRESENTATIVE;
    }
    if (rights.includes(AccountRights.WRITE)) {
        return AccountRole.CONTRIBUTOR;
    }
    return AccountRole.READ;
}

export function getAccountRoleRights(role: AccountRole): AccountRights[] {
    switch (role) {
        case AccountRole.ADMIN:
            return [AccountRights.ADMIN, AccountRights.INVITE, AccountRights.WRITE, AccountRights.READ];
        case AccountRole.REPRESENTATIVE:
            return [AccountRights.INVITE, AccountRights.WRITE, AccountRights.READ];
        case AccountRole.CONTRIBUTOR:
            return [AccountRights.WRITE, AccountRights.READ];
        case AccountRole.READ:
            return [AccountRights.READ];
        default:
            throw new Error("Unknown role");
    }
}

export type CreateAsFields = {
    createAs: CreateAs;
};

export type OrganisationInformationsFields = Pick<
    Account,
    "companyName" | "companySize" | "companyIndustry" | "companyRegistrationNumber" | "companyNationality" | "whiteLabelDomainId"
>;

export type CollaboratorsFields = {
    emails: { email: string }[];
    shouldNotifyByEmail: boolean;
};

export function createAccount(
    account: OrganisationInformationsFields,
    emailInvitations: string[],
    isRepresentative: boolean,
    notifyByEmail: boolean
): Promise<Account> {
    return doPost(
        "account",
        mapDeepObject<Record<string, unknown>, string>(
            { ...pickBy(account, isDefined), emails: emailInvitations, isRepresentative, notifyByEmail },
            isString,
            val => val.trim()
        )
    );
}
