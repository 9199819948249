import { EMAIL_REGEX } from "@vaultinum/vaultinum-api";
import {
    Button,
    CardWithFeedback,
    Controller,
    Form,
    Input,
    authController,
    message,
    useForm,
    useLang,
    useRequiredString,
    useWhiteLabelContext,
    yup
} from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import forgotPasswordImg from "../../assets/img/pages/forgot-password.png";
import { AccountLang } from "../../lang/AccountLang";
import { URL } from "../../services/routingService";

export default function ForgotPasswordPage(): JSX.Element {
    const [working, setWorking] = useState(false);
    const navigate = useNavigate();
    const { whiteLabelDomain } = useWhiteLabelContext();
    const [status, setStatus] = useState<"success" | "error" | undefined>();
    const lang = useLang<AccountLang>();
    const schema = yup.object({
        email: useRequiredString().trim().matches(EMAIL_REGEX, lang.shared.invalidEmail)
    });
    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm<{ email: string }>({ schema });

    const sendRecoverPasswordEmail = async ({ email }: { email: string }) => {
        try {
            setWorking(true);
            await authController.forgotPassword(email, whiteLabelDomain?.id);
            setStatus("success");
        } catch (error) {
            void message.error(lang.shared.sendEmailErrorMessage);
        } finally {
            setWorking(false);
        }
    };

    return (
        <CardWithFeedback
            extra={<img src={forgotPasswordImg} alt="forgotPasswordImg" />}
            title={lang.authentication.resetPassword}
            subtitle={lang.authentication.recoverPasswordInstructions}
            status={status}
            successExtra={
                <div className="flex flex-col gap-4">
                    <div className="max-w-96">{lang.authentication.resetPasswordInfoMessage}</div>
                    <div className="flex w-full justify-center">
                        <Button onClick={() => navigate(URL.login)} isLoading={false} type="default" children={lang.shared.back} />
                    </div>
                </div>
            }
        >
            <Form onSubmit={handleSubmit(sendRecoverPasswordEmail)}>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <Input.Email
                            {...field}
                            data-id="email-input"
                            placeholder={lang.shared.email}
                            autoFocus
                            disabled={working}
                            errorMessage={errors.email?.message}
                        />
                    )}
                />
                <div className="flex justify-between gap-2 pt-4">
                    <Button isLoading={false} isDisabled={working} type="default" onClick={() => navigate(URL.login)}>
                        {lang.shared.back}
                    </Button>
                    <Button htmlType="submit" isLoading={working}>
                        {lang.authentication.resetPassword}
                    </Button>
                </div>
            </Form>
        </CardWithFeedback>
    );
}
