import { fr } from "@vaultinum/vaultinum-sdk";
import { AccountLang } from "../AccountLang";

/* eslint max-len: 0 */
const frLang: AccountLang = {
    ...fr,
    account: {
        apps: {
            title: "Applications",
            plan: "Programme",
            payment: "Moyen de paiement"
        }
    },
    invitation: {
        invitationNotFound: "Invitation non trouvée",
        wrongMatchingEmail: "L'invitation ne correspond pas à votre email",
        invitationAlreadyAccepted: "Invitation déjà acceptée",
        accountNoLongerExists: "L'organisation n'existe plus",
        invitationRejected: "Invitation rejetée",
        invitationCanceled: "Invitation annulée",
        missingData: "Certaines données sont manquantes",
        unknownCode: "Code inconnu"
    },
    authentication: {
        loginInstructions: brandName => `Connectez-vous à votre compte ${brandName}`,
        login: "Se connecter",
        resetPassword: "Réinitialiser votre mot de passe",
        resetPasswordRetry: "Réinitialiser votre mot de passe de nouveau",
        recoverPasswordInstructions: "Merci d'indiquer votre adresse email afin de recevoir les instructions de réinitialisation.",
        resetPasswordInstructions: "Merci de choisir un nouveau mot de passe.",
        resetPasswordSuccessMessage: "Mot de passe réinitialisé avec succès",
        verifyEmailSuccessMessage: "Votre adresse email a bien été validée",
        revertSecondFactorAdditionSuccessMessage: "L'authentification à deux facteurs a été supprimée avec succès",
        oobCodeErrorMessage: "Votre demande a expiré ou ce lien a déjà été utilisé",
        resetPasswordErrorMessage: "La réinitialisation du mot de passe a échoué",
        resetPasswordInfoMessage: "Si vous avez un compte sur la plateforme, rendez-vous dans votre boîte email pour réinitialiser votre mot de passe"
    },
    homePage: {
        title: "Quelle action voulez-vous effectuer ?",
        manageAccountSettings: "Gérer mon organisation"
    },
    domain: {
        title: "Domaine",
        failedToGetDomain: "Une erreur est survenue lors de la récupération du domaine"
    },
    organisations: {
        title: "Organisations",
        searchOrganisation: "Rechercher une organisation",
        noOrganisationFound: "Aucune organisation n'a été trouvée dans ce domaine",
        viewOrganisation: "Voir l'organisation",
        creationDate: "Date de création",
        registeredDate: "Date d'enregistrement",
        users: "Utilisateurs"
    },
    sharedSettings: {
        noResultsSearch: "Aucun membre n'a été trouvé",
        adminRole: "Administrateur",
        representativeRole: "Mandataire",
        contributorRole: "Contributeur",
        readOnlyRole: "Lecture seule"
    },
    profileSettings: {
        warning: {
            emailNotVerified: "Votre adresse email n'a pas été confirmée"
        },
        title: {
            personalAccount: "Paramètres du profil"
        },
        menu: {
            profile: "Profil",
            companyAccounts: "Mes organisations",
            security: "Sécurité",
            preferences: "Préférences"
        },
        form: {
            birthdate: "Date de naissance",
            cellPhone: "Téléphone",
            email: "Email",
            currentPassword: "Mot de passe actuel",
            firstName: "Prénom",
            lastName: "Nom",
            nationality: "Nationalité",
            personalInformation: "Données personnelles",
            preferences: "Préférences",
            preferredLanguage: "Langue préférée"
        },
        companyAccounts: {
            title: "Mes organisations",
            whatIs: "Qu'est-ce qu'une organisation ?",
            definition: "Une organisation est la société à laquelle vous appartenez ou celle que vous représentez.",
            newAccount: "Nouvelle organisation",
            search: "Rechercher par nom d'organisation",
            leave: "Quitter",
            leaveAccount: companyName => `Quitter l'organisation "${companyName}" ?`,
            leaveAccountDescription: companyName => `Confirmez-vous que vous souhaitez quitter l'organisation "${companyName}" ?
            Vous ne serez plus en mesure d'accéder aux informations relatives à cette organisation.`,
            leaveAccountNonAdminDescription: "Une nouvelle invitation sera nécessaire si vous souhaitez rejoindre à nouveau cette organisation.",
            leaveAccountAdminDescription: `Comme vous êtes le seul "Administrateur" de cette organisation, vous pouvez soit
            supprimer entièrement l'organisation pour tous les utilisateurs, soit attribuer le rôle d'administrateur à un utilisateur existant.`,
            newAdminToAssign: "Assigner un nouvel administrateur",
            doNotAssignNewAdmin: "Ne pas assigner un nouvel administrateur",
            deleteAccount: "Supprimer l'organisation",
            deleteAccountConfirmation: companyName => `Êtes-vous sûr de vouloir supprimer l'organisation "${companyName}" ?`,
            deleteAccountConfirmationDescription: `Toutes les données relatives et tous les utilisateurs liés à cette organisation
            seront supprimés de façon permanente.`,
            acceptInvitation: "Accepter",
            rejectInvitation: "Refuser",
            invitedBy: senderName => `Invité par ${senderName}`,
            failedToJoinAccount: "Échec de l'adhésion à l'organisation",
            failedToRejectInvitation: "Echec du rejet de l'invitation"
        },
        security: {
            multiFactorAuthentication: "Authentification multifacteur",
            configure2FA: "Configurer l'authentification à deux facteurs",
            configure2FADescription: "Ajoutez une couche de sécurité supplémentaire à votre compte en activant l'authentification à deux facteurs.",
            scanQrCodeInstructions1: "Tout d'abord, téléchargez une application d'authentification sur votre téléphone.",
            scanQrCodeInstructions2: "Ouvrez votre application d'authentification et scannez le code QR.",
            cantScanIt: "Scan impossible ?",
            enterSetupKeyInstructions: "Ouvrez votre application d'authentification et saisissez la clé secrète suivante :",
            inputCodeInstructions: "Saisissez le code à 6 chiffres fourni par l'application d'authentification.",
            configureSuccessMessage: "Authentification à deux facteurs configurée avec succès.",
            configureErrorMessage: "Échec de la configuration de l'authentification à deux facteurs.",
            remove2FA: "Supprimer l'authentification à deux facteurs",
            remove2FAConfirmation: "Êtes-vous sûr de vouloir supprimer l'authentification à deux facteurs de votre compte ?",
            remove2FASuccessMessage: "Authentification à deux facteurs supprimée avec succès.",
            remove2FAErrorMessage: "Échec de la suppression de l'authentification à deux facteurs."
        }
    },
    accountSettings: {
        title: {
            organisationAccount: "Paramètres de l'organisation"
        },
        rightContent: {
            switchTo: "Changer l'organisation sélectionnée"
        },
        menu: {
            organisation: "Organisation",
            generalInformation: "Informations générales",
            userAccess: "Accès utilisateur",
            parties: "Parties",
            billingAndSubscriptions: "Facturation et abonnements",
            git: "Git",
            apiKeys: "Clés d'API"
        },
        userAccessView: {
            name: "Accès utilisateur",
            whatIs: "Qu'est-ce qu'un utilisateur ?",
            definition:
                "Un utilisateur est un collaborateur direct de votre entreprise (ex : un collègue ou un représentant légal). Lorsque vous lui envoyez une invitation, assurez-vous de définir les droits d'utilisateur appropriés.",
            invitations: "Invitations",
            changeRole: "Modifier le rôle",
            removeFromAccount: "Supprimer de l'organisation",
            removeFromAccountConfirmationTitle: user => `Retirer l'utilisateur ${user} de l'organisation ?`,
            removeFromAccountConfirmationContent: (user, account) => `L'utilisateur ${user} sera supprimé de l'organisation ${account}.`,
            inviteUser: {
                name: "Inviter un utilisateur",
                tooltip: "Le rôle d'administrateur est nécessaire pour envoyer une invitation.",
                emailPlaceholder: "Adresse e-mail à inviter",
                rolePlaceholder: "Rôle",
                errors: {
                    alreadyInAccountErrorMessage: "Cet utilisateur fait déjà partie de cette organisation.",
                    alreadyInvitedErrorMessage: "Cet utilisateur a déjà reçu une invitation."
                }
            },
            invitePending: "Invitation en attente...",
            sendInvite: "Envoyer",
            resendInvite: "Renvoyer l'invitation",
            cancelInvitation: "Annuler l'invitation",
            search: "Recherche par e-mail ou nom",
            findMember: "Trouver un membre",
            changeRoleTitle: user => `Modifier le rôle pour l'utilisateur ${user} ?`,
            selectRole: "Sélectionner le rôle",
            adminRoleDescription: "Possède un accès administrateur complet. Peut inviter et supprimer des utilisateurs et effectuer des paiements",
            representativeRoleDescription:
                "Peut inviter et peut contribuer à fournir des informations et des données à tous les services accessibles par cette organisation.",
            contributorRoleDescription: "Peut contribuer à fournir des informations et données à tous les services accessibles par cette organisation.",
            readOnlyRoleDescription: "Peut voir toutes les données des services en mode lecture seule",
            changeRoleSuccessMessage: "Rôle mis à jour avec succès",
            changeRoleErrorMessage: "Échec de la mise à jour du rôle"
        },
        billingView: {
            plansAndSubscriptionsTitle: "Plans et abonnements",
            billingInformationTitle: "Informations de facturation",
            updateSubscription: "Mettre à jour l'abonnement",
            updateSubscriptions: "Mettre à jour le(s) abonnement(s)",
            noPlanOrSubscription: "Votre organisation n'a actuellement aucun plan ou abonnement actif.",
            invoice: {
                failed: "Une erreur est survenue lors de la récupération des factures",
                invoiceNumber: "Numéro de facture",
                status: {
                    name: "Statut",
                    draft: "Brouillon",
                    void: "Annulé",
                    uncollectible: "Irrécupérable",
                    open: "Ouvert",
                    paid: "Payé"
                },
                date: "Date",
                amount: "Montant",
                label: {
                    "1": "Facture",
                    plural: () => "Factures"
                },
                product: "Produit",
                failedToDownloadInvoice: "Une erreur s'est produite lors du téléchargement de la facture"
            },
            subscription: {
                failed: "Une erreur est survenue lors de la récupération des abonnements",
                status: {
                    name: "Statut",
                    active: "Actif",
                    canceled: "Annulé",
                    incomplete_expired: "Expiré",
                    past_due: "En retard"
                },
                date: "Date",
                amount: "Montant",
                label: {
                    "1": "Abonnement",
                    plural: () => "Abonnements"
                },
                canceledAt: "Date d'annulation"
            },
            paymentMethods: {
                title: "Méthodes de paiement",
                expirationDate: "Date d'expiration",
                deletePaymentMethod: "Supprimer la méthode de paiement",
                deletePaymentMethodConfirmation: "Êtes-vous sûr de vouloir supprimer cette méthode de paiement ?",
                deletePaymentMethodFailed: "Une erreur est survenue lors de la suppression de la méthode de paiement",
                listPaymentMethodFailed: "Une erreur est survenue lors de la récupération des méthodes de paiement"
            }
        },
        partiesView: {
            name: "Parties",
            whatIs: "Qu'est-ce qu'une partie ?",
            definition:
                "Une partie est un fournisseur ou un bénéficiaire avec lequel vous travaillerez dans le cadre d'audits ou d'entiercements. Une partie peut consulter les informations partagées sur les audits ou entiercements ainsi que le nom, l'adresse et le numéro d'enregistrement de votre entreprise.",
            invitations: "Invitations",
            sentYouAPartyRequest: companyName => `${companyName} souhaite vous rejoindre en tant que partie`,
            isNowYourParty: companyName => `${companyName} vous a rejoint en tant que partie`,
            confirmReject: companyName => `Êtes-vous sûr de vouloir rejeter l'invitation de ${companyName} ?`,
            linkPartyAccount: "Lier une partie",
            startWorkingTogether: partnerCompanyName =>
                `Veuillez confirmer que vous acceptez d'être ajouté en tant que nouvelle partie de “${partnerCompanyName}”. En tant que partie, vous partagerez le nom, l'adresse et le numéro d'enregistrement de vos sociétés, et pourrez consulter les informations partagées d'audits et/ou d'entiercements.`,
            selectAnotherAccount: "Si vous voulez lier une autre organisation, sélectionnez l'organisation appropriée ci-dessous",
            noPartyYet: "Vous n'avez pas encore invité d'autres parties ni reçu d'invitation.",
            invitationRejected: "Invitation rejetée",
            invitationCancelled: "Invitation annulée",
            errorOccured: "Une erreur est survenue lors de la récupération de vos parties",
            invitationAlreadySent: "Invitation déjà envoyée",
            reportToAdmin:
                "Vous ne disposez pas des droits nécessaires pour inviter ou accepter les invitations des autres parties. Veuillez contacter l'administrateur de l'organisation"
        },
        gitView: {
            title: "Connexions à vos gestionnaires de version",
            newConnection: "Ajouter une nouvelle connexion",
            installingConnection: "Installation de la connexion...",
            deleteConnection: "Supprimer la connexion",
            deleteConnectionConfirmation: "Êtes-vous sûr de vouloir supprimer cette connexion git ?",
            selectConnection: "Sélectionnez la source de connexion :",
            featureComingSoon: "Bientôt disponible !",
            lastConnectionDate: "Connecté le",
            noResultsSearch: "Aucune connexion git trouvée",
            installationConfirmationMessage: provider =>
                `En cliquant sur "Confirmer", vous serez redirigé vers le site de ${provider} pour terminer l'installation.`,
            deletionConfirmationMessage: provider =>
                `En cliquant sur "Confirmer", vous serez redirigé vers le site de ${provider} pour supprimer la connexion.`,
            installed: "Installé", // to review
            disconnected: "Déconnecté", // to review
            fetching: "Récupération des répertoires de code", // to review
            fetchFailed: "Erreur de récupération des répertoires de code", // to review
            lastConnected: date => `Dernière connexion le ${date}` // to review
        },
        apiKeysView: {
            title: "Clés d'accès à l'API",
            newAccessToken: "Générer un nouveau jeton d'accès",
            description: brandName => `Les jetons générés permettent d'accéder à l'API ${brandName}`,
            newApiKeyTitle: "Nouveau jeton d'accès API",
            nameFieldPlaceholder: "Nom de la clé d'API...",
            keyEnvironments: {
                sandbox: "Clé Sandbox",
                production: "Clé de Production"
            },
            generate: "Générer",
            recapDescription: "Attention : Notez cette clé, elle ne sera plus affichée par la suite.",
            noResultFound: "Aucune clé d'API trouvée.",
            deleteApiKeyTitle: "Suppression de la clé d'API",
            deleteConfirmation: name => `Êtes-vous sûr de vouloir supprimer la clé d'API "${name}" ?`,
            maxReached: "Vous avez atteint le nombre maximum de clés d'API générées."
        }
    },
    onboarding: {
        ...fr.onboarding,
        verifyEmail: {
            sent: email => `Un email d'activation vous a été envoyé à ${email} pour vérifier et récupérer votre identité.`,
            confirm: "Merci de vérifier votre email afin de pouvoir continuer."
        },
        userProfile: {
            title: "Votre profil",
            successOnSave: "Profil sauvegardé avec succès.",
            errorOnSave: "La sauvegarde de votre profil a échoué."
        },
        invitations: {
            title: "Vos invitations",
            description: {
                "1": "Vous avez déjà été invité(e) par une organisation. Sélectionnez-la si vous souhaitez la rejoindre :",
                plural: count => `Vous avez déjà été invité(e) par ${count} organisations. Sélectionnez celles que vous souhaitez rejoindre :`
            },
            joinOrganisation: {
                "0": "Ignorer et créer ma propre organisation",
                "1": "Rejoindre cette organisation",
                plural: count => `Rejoindre ces ${count} organisations`
            },
            successOnJoin: {
                "1": "Une organisation rejointe avec succès.",
                plural: count => `${count} organisations rejointes avec succès.`
            },
            errorOnJoin: {
                "1": "L'acceptation d'une invitation a échoué.",
                plural: count => `L'acceptation de ${count} invitations a échoué.`
            }
        },
        account: {
            createAs: {
                title: "Créer en tant que",
                organisationMember: {
                    title: "Membre de l'organisation",
                    description: "Créez et gérez une organisation au nom de votre entreprise."
                },
                representative: {
                    title: "Mandataire",
                    description: "Créez et gérez une organisation au nom de votre client."
                }
            },
            accountInfo: {
                title: isRepresentative => `À propos de ${isRepresentative ? "l'organisation de votre client" : "votre organisation"}`
            },
            collaborate: {
                title: isRepresentative => (isRepresentative ? "Contact du client" : "Invitez vos associés"),
                description: isRepresentative => {
                    if (isRepresentative) {
                        return "Veuillez renseigner l'email de votre client. Il aura la possibilité de rejoindre cette organisation en tant qu'administrateur, ce qui lui donnera un contrôle total sur cette nouvelle organisation.";
                    }
                    return "Invitez des collaborateurs directs à travailler avec vous. Ils auront la possibilité de rejoindre cette organisation, leur accordant les droits de modifier et de télécharger des données. Vous pourrez toujours modifier leurs rôles ultérieurement dans la page des paramètres de l'organisation, ainsi qu'envoyer d'autres invitations.";
                },
                collaboratorEmail: isRepresentative => `Email du ${isRepresentative ? "contact client" : "collaborateur"}`,
                addAnotherCollaborator: isRepresentative => `Ajouter un autre ${isRepresentative ? "contact client" : "collaborateur"}`,
                sendInvitation: {
                    "1": "Envoyer l'invitation par email",
                    plural: () => "Envoyer les invitations par email"
                }
            },
            successOnSave: isRepresentative => `${isRepresentative ? "L'organisation de votre client" : "Votre organisation"} a été créée avec succès.`,
            errorOnSave: isRepresentative => `La création ${isRepresentative ? "de l'organisation de votre client" : "de votre organisation"} a échoué.`
        },
        moreInfoLink: "En savoir plus sur la raison et la manière dont nous utilisons vos informations."
    }
};
export default frLang;
