import { AuthError, MultiFactorInfo, User, UserCredential } from "firebase/auth";

export enum ProviderAuthError {
    INVALID_LOGIN_CREDENTIALS = "auth/invalid-login-credentials",
    INVALID_VERIFICATION_CODE = "auth/invalid-verification-code",
    MULTI_FACTOR_AUTH_REQUIRED = "auth/multi-factor-auth-required",
    REQUIRES_RECENT_LOGIN = "auth/requires-recent-login",
    TOO_MANY_REQUESTS = "auth/too-many-requests"
}

export type Prompters<T = unknown> = {
    withPassword?: (callback: (user: User, password: string) => Promise<UserCredential>) => Promise<UserCredential>;
    withTotp?: (callback: (oneTimePassword: string) => Promise<UserCredential>) => Promise<T>;
};

export interface AuthService {
    setPersistenceStrategy(rememberMe: boolean): Promise<void>;
    login(email: string, password: string, onError: (error: AuthError) => void, prompters?: Pick<Prompters, "withTotp">): Promise<UserCredential>;
    logout(): Promise<void>;
    setupTotp(
        user: User,
        appName: string,
        secondFactorDisplayName: string,
        onErrorCode: (error: AuthError) => void,
        prompters: Required<Pick<Prompters, "withPassword">>
    ): Promise<{
        secretKey: string;
        qrCodeUrl: string;
        finalizeEnrollment: (verificationCode: string) => Promise<void>;
    }>;
    unenrollTotp(user: User, mfaEnrollmentId: string, onErrorCode: (error: AuthError) => void, prompters: Required<Prompters>): Promise<unknown>;
    listEnrolledFactors(user: User): MultiFactorInfo[];
}
