import { Input } from "antd";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLang } from "../../../lang";
import { CheckCircleIcon, CrossCircleIcon, LoadingIcon } from "../Icons";

enum Keys {
    BACKSPACE_KEY = "Backspace",
    DELETE_KEY = "Delete",
    TAB_KEY = "Tab",
    LEFT_ARROW_KEY = "ArrowLeft",
    RIGHT_ARROW_KEY = "ArrowRight",
    V_KEY = "KeyV"
}

enum CodeInputState {
    IDLE = "IDLE",
    LOADING = "LOADING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR"
}

export default function CodeInput({
    codeLength,
    validateCode,
    showLoadingSpinner = true
}: {
    codeLength: number;
    validateCode: (codeInput: string) => Promise<void>;
    showLoadingSpinner?: boolean;
}): JSX.Element {
    const lang = useLang();
    const [code, setCode] = useState<(number | undefined)[]>(new Array(codeLength));

    function onCodeChanged(index: number, digit?: number) {
        if (index >= codeLength) {
            return;
        }
        setCode(prevState => Object.assign([], prevState, { [index]: digit }));
    }

    const inputRefs = useRef<HTMLInputElement[]>([]);
    const [codeInputState, setCodeInputState] = useState<CodeInputState>(CodeInputState.IDLE);

    function changeCodeFocus(index: number): void {
        inputRefs.current[index]?.focus();
    }

    function onPaste(event: React.ClipboardEvent<HTMLInputElement>, index: number): void | undefined {
        const value = event.clipboardData.getData("text");
        const digits = [...value.replace(/\D+/g, "")];
        if (codeInputState === CodeInputState.LOADING) {
            return;
        }
        for (const [i, digit] of digits.entries()) {
            onCodeChanged(index + i, Number(digit));
            if (index + i < codeLength - 1) {
                changeCodeFocus(index + i + 1);
            }
        }
        event.preventDefault();
    }

    function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>, index: number): void {
        const keyboardKeyCode = event.nativeEvent.code as Keys;
        const digit = event.nativeEvent.key;
        if (digit.match(/\d/g)) {
            onCodeChanged(index, Number(digit));
            if (index < codeLength - 1) {
                changeCodeFocus(index + 1);
            }
        } else {
            if (keyboardKeyCode === Keys.BACKSPACE_KEY) {
                if (code[index] === undefined) {
                    changeCodeFocus(index - 1);
                    onCodeChanged(index - 1);
                }
                onCodeChanged(index);
            }
            if (keyboardKeyCode === Keys.DELETE_KEY) {
                if (code[index] === undefined) {
                    changeCodeFocus(index + 1);
                    onCodeChanged(index + 1);
                }
                onCodeChanged(index);
            }
            if (keyboardKeyCode === Keys.LEFT_ARROW_KEY || (keyboardKeyCode === Keys.TAB_KEY && event.shiftKey)) {
                changeCodeFocus(index - 1);
            }
            if (keyboardKeyCode === Keys.RIGHT_ARROW_KEY || (keyboardKeyCode === Keys.TAB_KEY && !event.shiftKey)) {
                changeCodeFocus(index + 1);
            }
            if (keyboardKeyCode === Keys.V_KEY && (event.ctrlKey || event.metaKey)) {
                return;
            }
        }
        event.preventDefault();
    }

    async function validate(): Promise<void> {
        try {
            setCodeInputState(CodeInputState.LOADING);
            await validateCode(code.join(""));
            setCodeInputState(CodeInputState.SUCCESS);
        } catch {
            setCodeInputState(CodeInputState.ERROR);
        }
    }

    useEffect(() => {
        void (async function () {
            if (code.filter(value => value !== undefined).length === codeLength) {
                await validate();
            } else if (code.filter(value => value !== undefined).length !== 0) {
                setCodeInputState(CodeInputState.IDLE);
            }
        })();
    }, [code]);

    const [icon, label] = useMemo(() => {
        switch (codeInputState) {
            case CodeInputState.LOADING:
                return [showLoadingSpinner ? <LoadingIcon /> : <></>, ""];
            case CodeInputState.SUCCESS:
                return [<CheckCircleIcon color="green" />, lang.codeInput.successMessage];
            case CodeInputState.ERROR:
                return [<CrossCircleIcon color="red" />, lang.codeInput.errorMessage];
            default:
                return [];
        }
    }, [codeInputState]);

    return (
        <div className="space-y-2 py-4">
            <div className="flex gap-2 justify-center">
                {Array.from({ length: codeLength }, (_, index) => (
                    <Input
                        key={index}
                        ref={el => {
                            if (el?.input) {
                                inputRefs.current[index] = el.input;
                            }
                        }}
                        onKeyDown={event => onKeyDown(event, index)}
                        onPaste={event => onPaste(event, index)}
                        value={code[index]}
                        inputMode="numeric"
                        disabled={codeInputState === CodeInputState.LOADING}
                        className={classNames(
                            "h-10 w-10 text-center text-xl rounded-md",
                            { "border-danger": codeInputState === CodeInputState.ERROR },
                            { "border-success": codeInputState === CodeInputState.SUCCESS }
                        )}
                    />
                ))}
            </div>
            <div
                className={classNames("flex justify-center items-center h-5 space-x-2 text-sm transition-opacity", {
                    "text-danger": codeInputState === CodeInputState.ERROR,
                    "text-success": codeInputState === CodeInputState.SUCCESS,
                    "opacity-0": codeInputState === CodeInputState.IDLE
                })}
            >
                <div>{icon}</div>
                <div>{label}</div>
            </div>
        </div>
    );
}
