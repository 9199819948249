import { AuthError } from "firebase/auth";
import { CommonLang } from "../../lang";

function isAuthError(error: unknown): error is AuthError {
    return typeof error === "object" && error !== null && "code" in error && typeof error.code === "string";
}

export function isAuthErrorCode(error: unknown, code: string): boolean {
    return isAuthError(error) && error.code === code;
}

export function formatAuthError(error: AuthError, lang: CommonLang): string {
    if (isAuthError(error)) {
        return lang.error.auth[error.code] ?? lang.error.unknown;
    }
    return lang.error.unknown;
}
