import { RegisterForm, authController, formatAuthError, message, useLang, useUrlSearch, useWhiteLabelContext } from "@vaultinum/vaultinum-sdk";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as tagManager from "../../analytics/tagManager";
import { AccountLang } from "../../lang/AccountLang";
import { URL } from "../../services/routingService";

export default function RegisterPage({ logoSubtitle }: { logoSubtitle?: ReactNode }): JSX.Element {
    const navigate = useNavigate();
    const { whiteLabelDomain } = useWhiteLabelContext();
    const lang = useLang<AccountLang>();
    const { companyName, email: invitedEmail } = useUrlSearch() as { companyName?: string; email?: string };

    useEffect(() => {
        window.history.replaceState({}, "", window.location.pathname);
    }, []);

    async function doRegister({ email, password }: { email: string; password: string }) {
        await authController.register(email, password, whiteLabelDomain?.id);
        try {
            await authController.login(email, password, error => message.error(formatAuthError(error, lang)));
            tagManager.triggerRegistrationConversion();
            navigate(URL.index);
        } catch (error) {
            void message.error(formatAuthError(error, lang));
        }
    }

    return <RegisterForm doRegister={doRegister} loginUrl={URL.login} companyName={companyName} initialEmailValue={invitedEmail} logoSubtitle={logoSubtitle} />;
}
