import { Ref, forwardRef, useImperativeHandle } from "react";
import { Button, CodeInput, Modal } from "../../../design-system";
import { useLang } from "../../../lang";
import { useModal } from "../../hooks";
import useComponentPromise from "../../hooks/useComponentPromise";
import { UserCredential } from "../../services";

// TOTP = Time-based One-Time Password
function PromptTotpModal(
    _: unknown,
    ref: Ref<{ doOpen: (callback: (oneTimePassword: string) => Promise<UserCredential>) => Promise<UserCredential> }>
): JSX.Element {
    const lang = useLang();
    const { isOpen, doOpen, doClose } = useModal();

    const { trigger, doSubmit, doCancel, isWorking } = useComponentPromise<UserCredential>({
        show: doOpen,
        hidden: doClose,
        onError: error => {
            // Throw error so CodeInput component can display error message
            throw error;
        }
    });

    // Expose doOpen function to parent component
    useImperativeHandle(ref, () => ({ doOpen: trigger }), []);

    return (
        <Modal isOpen={isOpen} size="sm" hideFooter lang={lang}>
            <div>{lang.shared.secondFactorAuthenticationInstructions}</div>
            <CodeInput codeLength={6} validateCode={doSubmit} showLoadingSpinner={false} />
            <div className="flex justify-end gap-2">
                <Button children={lang.shared.cancel} isDisabled={isWorking} isLoading={false} type="default" onClick={doCancel} />
                {/* Disabled button just to give context to user. CodeInput component triggers validation automatically */}
                <Button children={lang.shared.verify} isLoading={isWorking} isDisabled />
            </div>
        </Modal>
    );
}

export default forwardRef(PromptTotpModal);
