import {
    AuthError,
    EmailAuthCredential,
    EmailAuthProvider,
    MultiFactorError,
    MultiFactorInfo,
    TotpMultiFactorGenerator,
    TotpSecret,
    User,
    UserCredential,
    browserLocalPersistence,
    browserSessionPersistence,
    getAuth,
    getMultiFactorResolver,
    multiFactor,
    reauthenticateWithCredential,
    signInWithEmailAndPassword
} from "firebase/auth";
import { AuthService, Prompters, ProviderAuthError } from "./auth.service";

export class FirebaseAuthService implements AuthService {
    private async loginWithFirstMultiFactor(error: MultiFactorError, oneTimePassword: string): Promise<UserCredential> {
        const mfaResolver = getMultiFactorResolver(getAuth(), error);
        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(mfaResolver.hints[0].uid, oneTimePassword);
        return mfaResolver.resolveSignIn(multiFactorAssertion);
    }

    private getCredentials(user: User, password: string): EmailAuthCredential {
        if (!user.email) {
            throw new Error("User email is required to create credentials.");
        }
        return EmailAuthProvider.credential(user.email, password);
    }

    private reauthenticate(user: User, password: string): Promise<UserCredential> {
        const credentials = this.getCredentials(user, password);
        return reauthenticateWithCredential(user, credentials);
    }

    async generateTotpSecret(user: User): Promise<TotpSecret> {
        const multiFactorSession = await multiFactor(user).getSession();
        return TotpMultiFactorGenerator.generateSecret(multiFactorSession);
    }

    generateTotpQrCodeUrl(user: User, totpSecret: TotpSecret, appName: string): string {
        return totpSecret.generateQrCodeUrl(user.email ?? undefined, appName);
    }

    enrollTotp(user: User, totpSecret: TotpSecret, code: string, secondFactorDisplayName: string): Promise<void> {
        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(totpSecret, code);
        return multiFactor(user).enroll(multiFactorAssertion, secondFactorDisplayName);
    }

    private async handleAuthError<T = unknown>(
        doAction: () => Promise<T>,
        onError?: (error: AuthError) => void,
        prompters?: Prompters<T>,
        isFirstRun = true
    ): Promise<T> {
        try {
            // Need to await here to catch the error
            return await doAction();
        } catch (error) {
            const recursiveHandleAuthError = <U>(callback: () => Promise<U>, p: Prompters<U> | undefined) => this.handleAuthError(callback, onError, p, false);
            switch (error?.code) {
                case ProviderAuthError.MULTI_FACTOR_AUTH_REQUIRED:
                    return recursiveHandleAuthError(() => {
                        if (!prompters?.withTotp) {
                            throw error;
                        }
                        return prompters.withTotp(oneTimePassword => this.loginWithFirstMultiFactor(error, oneTimePassword));
                    }, prompters);
                case ProviderAuthError.REQUIRES_RECENT_LOGIN: {
                    await recursiveHandleAuthError<unknown>(() => {
                        if (!prompters?.withPassword) {
                            throw error;
                        }
                        return prompters.withPassword((user: User, password: string) => this.reauthenticate(user, password));
                    }, prompters);

                    // Retry the original action
                    return recursiveHandleAuthError(doAction, prompters);
                }
                case ProviderAuthError.INVALID_LOGIN_CREDENTIALS:
                case ProviderAuthError.INVALID_VERIFICATION_CODE:
                case ProviderAuthError.TOO_MANY_REQUESTS: {
                    if (isFirstRun) {
                        throw error;
                    }
                    onError?.(error);
                    // Retry the original action
                    return recursiveHandleAuthError(doAction, prompters);
                }
                default:
                    throw error;
            }
        }
    }

    setPersistenceStrategy(rememberMe: boolean): Promise<void> {
        const persistenceStrategy = rememberMe ? browserLocalPersistence : browserSessionPersistence;
        return getAuth().setPersistence(persistenceStrategy);
    }

    login(
        email: string,
        password: string,
        onErrorCode?: (error: AuthError) => void,
        prompters?: { withTotp?: (callback: (oneTimePassword: string) => Promise<UserCredential>) => Promise<UserCredential> }
    ): Promise<UserCredential> {
        return this.handleAuthError<UserCredential>(() => signInWithEmailAndPassword(getAuth(), email, password), onErrorCode, prompters);
    }

    logout(): Promise<void> {
        return getAuth().signOut();
    }

    async setupTotp(
        user: User,
        appName: string,
        secondFactorDisplayName: string,
        onErrorCode: (error: AuthError) => void,
        prompters: Pick<Prompters, "withPassword">
    ): Promise<{
        secretKey: string;
        qrCodeUrl: string;
        finalizeEnrollment: (verificationCode: string) => Promise<void>;
    }> {
        return this.handleAuthError<{
            secretKey: string;
            qrCodeUrl: string;
            finalizeEnrollment: (verificationCode: string) => Promise<void>;
        }>(
            async () => {
                const totpSecret = await this.generateTotpSecret(user);
                const qrCodeUrl = this.generateTotpQrCodeUrl(user, totpSecret, appName);
                const finalizeEnrollment = (verificationCode: string) => this.enrollTotp(user, totpSecret, verificationCode, secondFactorDisplayName);
                return {
                    secretKey: totpSecret.secretKey,
                    qrCodeUrl,
                    finalizeEnrollment
                };
            },
            onErrorCode,
            prompters
        );
    }

    unenrollTotp(
        user: User,
        mfaEnrollmentId: string,
        onErrorCode: (error: AuthError) => void,
        prompters: {
            withPassword: (callback: (user: User, password: string) => Promise<UserCredential>) => Promise<UserCredential>;
            withTotp: (callback: (oneTimePassword: string) => Promise<UserCredential>) => Promise<unknown>;
        }
    ): Promise<unknown> {
        return this.handleAuthError<unknown>(() => multiFactor(user).unenroll(mfaEnrollmentId), onErrorCode, prompters);
    }

    listEnrolledFactors(user: User): MultiFactorInfo[] {
        return multiFactor(user).enrolledFactors;
    }
}

export const singletonFirebaseAuthService = new FirebaseAuthService();
