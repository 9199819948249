import { Ref, forwardRef, useImperativeHandle } from "react";
import { Button, Controller, Form, Input, Modal } from "../../../design-system";
import { useLang } from "../../../lang";
import { useAuthContext } from "../../contexts";
import { isAuthErrorCode } from "../../helpers";
import { useComponentPromise, useModal, useRequiredString } from "../../hooks";
import { User, yup } from "../../services";
import { ProviderAuthError } from "../../services/auth/auth.service";
import { useForm } from "../../tools";

function PromptPasswordModal<T>(_: unknown, ref: Ref<{ doOpen: (callback: (user: User, password: string) => Promise<T>) => Promise<T> }>): JSX.Element {
    const lang = useLang();
    const {
        reset,
        handleSubmit,
        control,
        formState: { errors, isValid }
    } = useForm<{ password: string }>({ schema: yup.object({ password: useRequiredString() }), mode: "onChange" });
    const { isOpen, doOpen, doClose } = useModal(reset);
    const { user } = useAuthContext();

    const { trigger, doSubmit, doCancel, isWorking } = useComponentPromise<T>({
        show: doOpen,
        hidden: doClose,
        onError: error => {
            if (isAuthErrorCode(error, ProviderAuthError.MULTI_FACTOR_AUTH_REQUIRED)) {
                doClose();
            }
        }
    });

    // Expose doOpen function to parent component
    useImperativeHandle(
        ref,
        () => ({
            doOpen: (callback: (user: User, password: string) => Promise<T>) => {
                if (!user) {
                    throw new Error("User is not authenticated.");
                }
                return trigger(password => callback(user, password));
            }
        }),
        [user]
    );

    return (
        <Modal isOpen={isOpen} size="sm" hideFooter lang={lang}>
            <Form onSubmit={handleSubmit(inputs => doSubmit(inputs.password))} className="justify-between w-full h-full">
                <div>{lang.shared.additionalSecurityVerification}</div>
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <Input.Password {...field} label={lang.shared.password} disabled={isWorking} errorMessage={errors.password?.message} />
                    )}
                />
                <div className="flex justify-end gap-2">
                    <Button children={lang.shared.cancel} isDisabled={isWorking} isLoading={false} type="default" onClick={doCancel} />
                    <Button htmlType="submit" children={lang.shared.verify} isDisabled={!isValid} isLoading={isWorking} />
                </div>
            </Form>
        </Modal>
    );
}

export default forwardRef(PromptPasswordModal);
