import { useState } from "react";
import { AuthenticationLogo, CardWithFeedback, LoginForm, useLang } from "../../../../common";

export default function HiddenLoginPage(): JSX.Element {
    const lang = useLang();
    const [isTotpDisplayed, setIsTotpDisplayed] = useState(false);

    return (
        <div className="flex h-full items-center justify-center">
            <CardWithFeedback
                extra={<AuthenticationLogo />}
                title={isTotpDisplayed ? lang.shared.secondFactorAuthentication : lang.shared.signIn}
                subtitle={isTotpDisplayed ? lang.shared.secondFactorAuthenticationInstructions : undefined}
            >
                <LoginForm
                    ref={instance => setIsTotpDisplayed(instance?.isTotpDisplayed ?? false)}
                    onError={error => {
                        const url = error?.response?.data?.redirectTo;
                        if (url) {
                            window.location.assign(url);
                        }
                    }}
                />
            </CardWithFeedback>
        </div>
    );
}
