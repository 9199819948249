import { Account } from "@vaultinum/vaultinum-api";
import { Alert, ColCards, ColCardWithRadioProps, GithubIcon, Modal, useLang } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { AccountLang } from "../../../../lang/AccountLang";

const GIT_PROVIDERS: ColCardWithRadioProps<Account.GitConnectionProvider>[] = [
    {
        children: "GitHub",
        icon: GithubIcon,
        radio: {
            id: Account.GitConnectionProvider.GITHUB
        }
    }
];

function getConnectionLink(key?: Account.GitConnectionProvider) {
    switch (key) {
        case Account.GitConnectionProvider.GITHUB:
            return process.env.REACT_APP_GITHUB_CONNECTION;
        case Account.GitConnectionProvider.GITLAB: // to define
            return process.env.REACT_APP_GITLAB_CONNECTION;
        default:
            return "";
    }
}

export default function AddGitConnectionModal({ isOpen, doClose }: { isOpen: boolean; doClose: () => void }): JSX.Element {
    const lang = useLang<AccountLang>();
    const [selectedItem, setSelectedItem] = useState<Account.GitConnectionProvider>(Account.GitConnectionProvider.GITHUB);
    const selectedGitProvider = GIT_PROVIDERS.find(provider => provider.radio.id === selectedItem);

    function handleGitRedirection() {
        const link = getConnectionLink(selectedGitProvider?.radio.id);
        window.open(link, "_blank");
    }

    return (
        <Modal title={lang.accountSettings.gitView.newConnection} isOpen={isOpen} onConfirm={handleGitRedirection} onClose={doClose} lang={lang}>
            <div className="space-y-2">
                <p>{lang.accountSettings.gitView.selectConnection}</p>
                <div className="flex gap-4">
                    {GIT_PROVIDERS.map(provider => (
                        <ColCards.WithRadio<Account.GitConnectionProvider>
                            key={provider.radio.id}
                            children={provider.children}
                            radio={{
                                id: provider.radio.id,
                                selectedId: selectedItem,
                                onValueChange: setSelectedItem
                            }}
                        />
                    ))}
                </div>
                {selectedGitProvider && typeof selectedGitProvider.children === "string" && (
                    <Alert.Info
                        message={lang.accountSettings.gitView.installationConfirmationMessage(selectedGitProvider.children)}
                    />
                )}
            </div>
        </Modal>
    );
}
