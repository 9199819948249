import { useRef, useState } from "react";

type Callback<T> = (input: string) => Promise<T>;

export enum CustomError {
    CANCELLED_BY_USER = "CANCELLED_BY_USER"
}

/**
 * This hook is used to manage a component that requires a promise to be resolved.
 * It is useful for components that require user input before continuing.
 **/
export default function useComponentPromise<T = void>({
    show,
    hidden,
    onCancel,
    onError
}: {
    show: () => void;
    hidden: () => void;
    onCancel?: () => void;
    onError?: (error: unknown) => void;
}): {
    trigger: (callback: Callback<T>) => Promise<T>;
    doCancel: () => void;
    doSubmit: (input: string) => Promise<void>;
    isWorking: boolean;
} {
    const callbackRef = useRef<Callback<T>>();
    const resolveRef = useRef<(value: T) => unknown>();
    const rejectRef = useRef<(reason: string) => unknown>();
    const [isWorking, setIsWorking] = useState(false);

    function trigger(callback: Callback<T>): Promise<T> {
        show();
        callbackRef.current = callback;
        const promise = new Promise<T>((resolve, reject) => {
            resolveRef.current = resolve;
            rejectRef.current = reject;
        });
        return promise;
    }

    function doCancel(): void {
        onCancel?.();
        hidden();
        rejectRef.current?.(CustomError.CANCELLED_BY_USER);
    }

    async function doSubmit(input: string): Promise<void> {
        setIsWorking(true);
        try {
            if (!callbackRef.current || !resolveRef.current) {
                throw new Error("Usage error. Call trigger first.");
            }
            const result = await callbackRef.current(input);
            resolveRef.current(result);
            hidden();
        } catch (error) {
            onError?.(error);
            rejectRef.current?.(error);
        } finally {
            setIsWorking(false);
        }
    }

    return { trigger, doCancel, doSubmit, isWorking };
}
